'use client';

import { SnackbarProvider } from 'notistack';
import React from 'react';

export default function NotificationsProvider({ children }: { children: React.ReactNode }) {
  return (
    <SnackbarProvider
      maxSnack={4}
    >
      {children}
    </SnackbarProvider>
  );
}
