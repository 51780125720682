'use client';

import { usePathname } from 'next/navigation';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { historyAtom } from '@/src/stores/history';

export default function HistoryProvider({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  const setHistory = useSetAtom(historyAtom);

  useEffect(() => {
    if (!pathname) return;

    setHistory(prev => {
      if (prev[prev.length - 1] !== pathname) {
        return [...prev, pathname];
      }
      return prev;
    });
  }, [pathname, setHistory]);

  return children;
}
