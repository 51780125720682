'use client';

import { Session } from 'next-auth';
import { SessionProvider as Provider } from 'next-auth/react';
import React from 'react';

export default function SessionProvider({ session, children }: { session: Session | null, children: React.ReactNode }) {
  return (
    <Provider
      session={session}
      refetchOnWindowFocus={false}
      refetchInterval={60 * 5}
    >
      {children}
    </Provider>
  );
}
